import { defineStore } from 'pinia';
import { formatDate } from '@/utils/helpers';
import * as helpers from '@/utils/helpers';
import { apiGet, apiPost, apiPut } from '@/utils/api';
import getPath from '@/utils/getPath';

export const useDocumentsStore = defineStore('documents', {
  state: () => ({
    documents: [],
    currentDocument: null,
    currnetDocumentVersion: null,
    currentDocumentId: null,
    errorMessage: null,
    isLoading: true,
    documentsRecords: 0,
    documentsLimit: 10,
    documentsPage: 1,
    documentsDirection: 'ASC',
    documentsField: 'mod_timest',
  }),
  getters: {
    sortDocs: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.documents);
      };
    },
    documentsRecords: state => {
      return state.documents.length;
    },
    documentsPaginated: state => {
      const from = Number(state.documentsLimit) * Number(state.documentsPage);
      const to = from - Number(state.documentsLimit);
      return state.documents.slice(to, from);
    },
  },
  actions: {
    async fetchAssociatedDocuments(revologySystemNumber) {
      this.isLoading = true;
      try {
        const response = await apiGet(
          getPath(
            `docs/workItem/${revologySystemNumber}`,
            process.env.VUE_APP_DOC_MGMT_API_GATEWAY_ENDPOINT
          )
        );

        this.documents = response ?? [];
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDocumentByWorkNumberAndVersion(documentUUID, versionNumber) {
      this.isLoading = true;
      try {
        const response = await apiGet(
          getPath(
            `docs/${documentUUID}/version/${versionNumber}`,
            process.env.VUE_APP_DOC_MGMT_API_GATEWAY_ENDPOINT
          )
        );
        this.currnetDocumentVersion = response.doc_version;
        this.currentDocumentId = response.doc_id;
        this.currentDocument = response.html_text || [];
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isLoading = false;
      }
    },
    async updateDocument(updatedHTML) {
      this.isLoading = true;

      const payload = {
        html_text: updatedHTML,
        doc_id: this.currentDocumentId,
        doc_version: this.currnetDocumentVersion,
      };

      try {
        const response = apiPut(
          getPath(
            `docs/${this.doc_id}`,
            process.env.VUE_APP_DOC_MGMT_API_GATEWAY_ENDPOINT
          ),
          payload
        );
        this.currnetDocumentVersion = response.doc_version;
        this.currentDocumentId = response.doc_id;
        this.currentDocument = response.html_text || [];
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isLoading = false;
      }
    },
    validateWorkItem(workItem) {
      this.errorMessage = null;
      const work_item_required_fields = ['work_item_id'];
      let missingFields = [];

      work_item_required_fields.forEach(requiredField => {
        if (!workItem[requiredField]) missingFields.push(`${requiredField}`);
      });

      if (missingFields.length > 0) {
        let readableMessage =
          'There are missing fields in this work item! Fields missing are: \n';
        missingFields.forEach(field => {
          let readableField = field.replaceAll('_', ' ');
          readableMessage += ' ' + readableField + ',';
        });
        this.errorMessage = readableMessage.substring(
          0,
          readableMessage.length - 1
        );
      }
    },

    async createNewDocument(workItem, currentUsersName) {
      this.validateWorkItem(workItem);
      if (this.errorMessage) return;
      this.isLoading = true;
      const payload = {
        work_item_id: workItem.work_item_id,
        doc_type: 'appeal',
        doc_template: 'appeal-letter-v1',
        description: `Appeal document`,
        params: {
          client: {
            // who were acting on behalf of
            logo: '',
            footer: '',
            name: '',
            addr1: '',
            addr2: '',
            city: '',
            state: '',
            postal_code: '',
          },
          payer: {
            //insurance company
            dept_name: workItem.department,
            payer_name: workItem.current_payer_name,
            addr1: '',
            addr2: '',
            city: '',
            state: '',
            postal_code: '',
          },
          patient_name: workItem.patient_name,
          health_plan_id: workItem.policy_number,
          guarantor_name: workItem.subscriber_name,
          group_number: workItem.group_number,
          claim_number: workItem.claim_number,
          date_of_service:
            workItem.service_end_date == null
              ? null
              : formatDate(workItem.service_end_date),
          service_name: workItem.procedure_code_desc,
          service_cpt: workItem.procedure_code,
          initial_claim_submission_date:
            workItem.initial_claim_date == null
              ? null
              : formatDate(workItem.initial_claim_date),
          claim_tx_submission_date:
            workItem.last_claim_date == null
              ? null
              : formatDate(workItem.last_claim_date),
          rcm_rep_name: currentUsersName,
          rcm_rep_contact_num: null, // TODO: Supply an actual value
        },
      };

      try {
        const response = await apiPost(
          getPath('docs', process.env.VUE_APP_DOC_MGMT_API_GATEWAY_ENDPOINT),
          payload
        );

        this.currentDocument = response.html_text || [];
        this.currentDocumentId = response.doc_id;
        this.currnetDocumentVersion = 1;
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
