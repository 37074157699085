import { defineStore } from 'pinia';
import { getAuthenticatedHeaders } from '../utils/auth';
import getPath from '@/utils/getPath';
import { apiPut } from '@/utils/api';

export const DAILY_GOAL_STREAK_USER_METRIC_TYPE_ID = 1;
export const DAILY_GOAL_USER_METRIC_TYPE_ID = 2;
export const DAILY_COMPLETE_USER_METRIC_TYPE_ID = 3;
export const WEEKLY_COMPLETE_USER_METRIC_TYPE_ID = 4;
export const MONTHLY_COMPLETE_USER_METRIC_TYPE_ID = 5;
export const DAILY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID = 6;
export const WEEKLY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID = 7;
export const MONTHLY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID = 8;
export const DAILY_WIP_USER_METRIC_TYPE_ID = 9;
export const WEEKLY_WIP_USER_METRIC_TYPE_ID = 10;
export const MONTHLY_WIP_USER_METRIC_TYPE_ID = 11;
export const DAILY_ASSIGNED_USER_METRIC_TYPE_ID = 12;
export const WEEKLY_ASSIGNED_USER_METRIC_TYPE_ID = 13;
export const MONTHLY_ASSIGNED_USER_METRIC_TYPE_ID = 14;

export const useMetrics = defineStore('metrics', {
  state: () => ({
    // Generic State
    isLoading: true,
    currentTabID: null,
    showMetrics: false,

    // Active User (Logged in User)
    activeUsername: null,
    activeUserID: null,
    activeUserDailyGoalStreak: 0,
    activeUserDailyGoal: 0,
    activeUserDailyComplete: 0,
    activeUserWeeklyComplete: 0,
    activeUserMonthlyComplete: 0,
    activeUserDailyTimeToComplete: 0,
    activeUserWeeklyTimeToComplete: 0,
    activeUserMonthlyTimeToComplete: 0,
    activeUserDailyWip: 0,
    activeUserWeeklyWip: 0,
    activeUserMonthlyWip: 0,
    activeUserDailyAssigned: 0,
    activeUserWeeklyAssigned: 0,
    activeUserMonthlyAssigned: 0,

    // Selected User (Selected from Users Table)
    selectedUserDailyGoalStreak: 0,
    selectedUserDailyGoal: 0,
    selectedUserDailyComplete: 0,
    selectedUserWeeklyComplete: 0,
    selectedUserMonthlyComplete: 0,
    selectedUserDailyTimeToComplete: 0,
    selectedUserWeeklyTimeToComplete: 0,
    selectedUserMonthlyTimeToComplete: 0,
    selectedUserDailyWip: 0,
    selectedUserWeeklyWip: 0,
    selectedUserMonthlyWip: 0,
    selectedUserDailyAssigned: 0,
    selectedUserWeeklyAssigned: 0,
    selectedUserMonthlyAssigned: 0,
  }),
  getters: {
    getCompletedRatio() {
      if (this.activeUserDailyGoal) {
        const complete = Number(this.activeUserDailyComplete);
        const goal = Number(this.activeUserDailyGoal);
        if (goal === 0 && complete === 0) {
          return 100;
        }
        return Math.floor((complete * 100) / goal);
      }
    },
  },
  actions: {
    setShowMetrics(val) {
      this.showMetrics = val;
    },
    getGoal(activeUser = false, goal) {
      return activeUser
        ? this[`activeUser${goal}`]
        : this[`selectedUser${goal}`];
    },
    async publishEvent(detailType, detail) {
      const headers = await getAuthenticatedHeaders();

      const payload = {
        detailType,
        detail,
      };

      this.isLoading = true;
      try {
        const response = await fetch(
          getPath(
            'metrics/events',
            process.env.VUE_APP_INSURANCE_API_GATEWAY_ENDPOINT
          ),
          {
            method: 'POST',
            headers: headers.headers,
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async setTimeSpent(auxo_user_id, work_item_id, timespent_ms) {
      const headers = await getAuthenticatedHeaders();

      const payload = {
        auxo_user_id: auxo_user_id,
        work_item_id: work_item_id,
        time_spent: Number(timespent_ms),
      };

      try {
        // we don't want this blocking other network activity, I think sending it once should suffice
        fetch(
          getPath(
            'metrics/workItem/timeSpent',
            process.env.VUE_APP_INSURANCE_API_GATEWAY_ENDPOINT
          ),
          {
            method: 'POST',
            headers: headers.headers,
            body: JSON.stringify(payload),
          }
        ).then(data => console.debug(data.json()));
      } catch (error) {
        console.error(error);
      }
    },
    async getUserMetrics(auxo_user_id) {
      const headers = await getAuthenticatedHeaders();
      this.isLoading = true;
      this.selectedUserDailyGoal = null;
      if (auxo_user_id) {
        this.selectedUserDailyGoalStreak = 0;
        this.selectedUserDailyGoal = 0;
        this.selectedUserDailyComplete = 0;
        this.selectedUserWeeklyComplete = 0;
        this.selectedUserMonthlyComplete = 0;
        this.selectedUserDailyTimeToComplete = 0;
        this.selectedUserWeeklyTimeToComplete = 0;
        this.selectedUserMonthlyTimeToComplete = 0;
        this.selectedUserDailyWip = 0;
        this.selectedUserWeeklyWip = 0;
        this.selectedUserMonthlyWip = 0;
        this.selectedUserDailyAssigned = 0;
        this.selectedUserWeeklyAssigned = 0;
        this.selectedUserMonthlyAssigned = 0;
      }

      try {
        fetch(
          getPath(
            `metrics?userId=${auxo_user_id}`,
            process.env.VUE_APP_INSURANCE_API_GATEWAY_ENDPOINT
          ),
          {
            method: 'GET',
            headers: headers.headers,
          }
        )
          .then(response => response.json())
          .then(({ data }) => {
            const dailyGoal = data.daily_goal;
            const dailyMetrics = data.daily_metrics;
            const weeklyMetrics = data.weekly_metrics;
            const monthlyMetrics = data.monthly_metrics;
            const allMetrics = [
              ...dailyGoal,
              ...dailyMetrics,
              ...weeklyMetrics,
              ...monthlyMetrics,
            ];
            allMetrics.forEach(metric => {
              const { user_metric_type_id, value } = metric;
              switch (user_metric_type_id) {
                case DAILY_GOAL_STREAK_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserDailyGoalStreak = value)
                    : (this.selectedUserDailyGoalStreak = value);
                  break;
                case DAILY_GOAL_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserDailyGoal = value)
                    : (this.selectedUserDailyGoal = value);
                  break;
                case DAILY_COMPLETE_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserDailyComplete = value)
                    : (this.selectedUserDailyComplete = value);
                  break;
                case WEEKLY_COMPLETE_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserWeeklyComplete = value)
                    : (this.selectedUserWeeklyComplete = value);
                  break;
                case MONTHLY_COMPLETE_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserMonthlyComplete = value)
                    : (this.selectedUserMonthlyComplete = value);

                  break;
                case DAILY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserDailyTimeToComplete = value)
                    : (this.selectedUserDailyTimeToComplete = value);
                  break;
                case WEEKLY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserWeeklyTimeToComplete = value)
                    : (this.selectedUserWeeklyTimeToComplete = value);
                  break;
                case MONTHLY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserMonthlyTimeToComplete = value)
                    : (this.selectedUserMonthlyTimeToComplete = value);
                  break;
                case DAILY_WIP_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserDailyWip = value)
                    : (this.selectedUserDailyWip = value);
                  break;
                case WEEKLY_WIP_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserWeeklyWip = value)
                    : (this.selectedUserWeeklyWip = value);
                  break;
                case MONTHLY_WIP_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserMonthlyWip = value)
                    : (this.selectedUserMonthlyWip = value);
                  break;
                case DAILY_ASSIGNED_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserDailyAssigned = value)
                    : (this.selectedUserDailyAssigned = value);
                  break;
                case WEEKLY_ASSIGNED_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserWeeklyAssigned = value)
                    : (this.selectedUserWeeklyAssigned = value);
                  break;
                case MONTHLY_ASSIGNED_USER_METRIC_TYPE_ID:
                  this.activeUserID == auxo_user_id
                    ? (this.activeUserMonthlyAssigned = value)
                    : (this.selectedUserMonthlyAssigned = value);
                  break;
                default:
                  break;
              }
            });
          });

        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async setUserGoal(auxo_user_id, goal) {
      this.isLoading = true;
      try {
        const headers = await getAuthenticatedHeaders();

        const payload = { value: goal };

        const response = await apiPut(
          getPath(`users/${auxo_user_id}/goal`),
          payload,
          headers
        );

        this.activeUserID == auxo_user_id
          ? (this.activeUserDailyGoal = response?.data?.data?.data)
          : (this.selectedUserDailyGoal = response?.data?.data?.data);

        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async queryForUserMetrics(userID = null) {
      this.isLoading = true;

      if (!userID && !this.activeUserID) {
        this.isLoading = false;
        return;
      }

      await this.getUserMetrics(userID ?? this.activeUserID).then(() => {
        this.isLoading = false;
      });
    },
  },
});
