<template>
  <div class="w-20 h-full text-gray-100 bg-primary">
    <ul class="flex flex-col h-full bg-primary text-xs text-center ml-1">
      <template v-for="navItem in leftNavRoutes" :key="navItem.name">
        <router-link
          v-if="rbac_store.hasAccessToRoute(navItem.name)"
          v-slot="{ href, navigate }"
          :to="navItem.path"
        >
          <div
            :class="
              activeTab == navItem.path ? activeStyle[0] : inactiveStyle[0]
            "
          >
            <li
              class="flex flex-col content-center items-center p-1 py-4 bg-primary text-gray-100 pr-1.5"
              :class="
                activeTab == navItem.path ? activeStyle[1] : inactiveStyle[1]
              "
            >
              <component :is="navItem.icon" class="w-7 h-7"></component>
              <a class="pt-1" :href="href" @click="navigate">{{
                navItem.sidebarName
              }}</a>
            </li>
          </div>
        </router-link>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';

//Pinia Stores
import { routes } from '../router/index';
import { useRbacStore } from '@/stores/useRbac';

const route = useRoute();
const rbac_store = useRbacStore();

const activeTab = ref(null);

// styling
const activeStyle = ['bg-primary', '!bg-[#f3f4f6] text-primary rounded-l'];
const inactiveStyle = ['bg-gray-100', '!text-white rounded-none'];

const setActiveTab = id => (activeTab.value = id);

// watch
watch(
  route,
  () => {
    const count = (route.path.match(/\//g) || []).length;
    if (count > 1) {
      setActiveTab(`/${route.path.split('/')[count - 1]}`);
    } else setActiveTab(route.path);
  },
  { deep: true, immediate: true }
);

/**
 * Returns All Routes With Sidebar True
 * @returns - Routes With Sidebar True
 */
const getLeftNavRoutes = async () => {
  return Promise.all(
    routes
      ?.filter(route => route.sidebar == true)
      .map(async route => {
        if (route.featureFlag != null) {
          const featureFlagPass = Boolean(
            process.env[route.featureFlag] == 'true'
          );
          if (!featureFlagPass) return null;
        }

        return route;
      })
  ).then(routes => routes.filter(v => v));
};
const leftNavRoutes = await getLeftNavRoutes();
</script>
