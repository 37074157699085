import { defineStore } from 'pinia';
import { apiPost } from '@/utils/api';
import getPath from '@/utils/getPath';

export const useChatbotStore = defineStore('chatbot', {
  state: () => ({
    notFound: false,
    responseLoading: false,
    sessionID: '',
  }),
  getters: {
    //no getters yet
  },
  actions: {
    async generateAndFormatResponse(rspGroup, user, options = {}) {
      const payload = {
        message: options.prompt || '',
        extraDetails: {
          phase: rspGroup.phase,
          status: rspGroup.status,
          reason: rspGroup.reason,
        },
        userID: user.auxo_user_id,
        sessionID: this.sessionID || crypto.randomUUID(),
        rsn: rspGroup.rsn,
      };

      const endpoint = options.endpoint ? `chat/${options.endpoint}` : 'chat';
      const response = await apiPost(getPath(endpoint), payload);

      this.sessionID = response.sessionID;
      const output = response.response_text ?? null;
      const presignedURL = response.presignedURL ?? '';

      const text = {
        body: output,
        imageUrl: '',
        linkText: '',
        linkUrl: presignedURL,
        sessionID: this.sessionID,
      };

      return text;
    },
  },
});
