// Lucid chart to visualize this authentication workflow
// https://lucid.app/lucidchart/a8004b41-0efb-4b38-ae49-cbff64c8a1a1/edit?viewport_loc=-614%2C-1139%2C7721%2C3766%2C0_0&invitationId=inv_71011c85-1db8-4ff4-a7ca-72a52e84769e

import { jwtDecode } from 'jwt-decode';
import router from '../router/index';

const domain = process.env.VUE_APP_COGNITO_POOL_DOMAIN;
const client_id = process.env.VUE_APP_COGNITO_POOL_CLIENT_ID;
const redirect_uri = process.env.VUE_APP_COGNITO_POOL_REDIRECT_SIGN_IN;
const logout_uri = process.env.VUE_APP_COGNITO_POOL_REDIRECT_SIGN_OUT;

export function signIn() {
  const url = `https://${domain}/login?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&prompt=login`;
  // Redirect the user to cognito hosted UI
  window.location.replace(url);
}

export async function signOut() {
  const refresh_token = sessionStorage.getItem('refresh_token');
  if (!refresh_token) return false;

  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  const params = new URLSearchParams({
    token: refresh_token,
    client_id,
  });

  const requestOptions = {
    method: 'POST',
    headers,
    body: params,
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      `https://${domain}/oauth2/revoke`,
      requestOptions
    );
    // Check if the response status is ok
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    clearTokens();
    window.location.replace(
      `https://${domain}/logout?client_id=${client_id}&logout_uri=${logout_uri}&response_type=code&client_id=${client_id}`
    );
  } catch (error) {
    console.error('Sign out failed:', error);
    throw error;
  }
}

export async function getTokens(code = '', refresh_token = '') {
  const grant_type = code ? 'authorization_code' : 'refresh_token';

  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  const params = new URLSearchParams({
    grant_type,
    code,
    refresh_token,
    client_id,
    redirect_uri,
  });

  const requestOptions = {
    method: 'POST',
    headers,
    body: params,
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      `https://${domain}/oauth2/token`,
      requestOptions
    );
    // Check if the response status is ok
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const { id_token, access_token, refresh_token } = await response.json();

    setTokens(id_token, access_token, refresh_token);
  } catch (error) {
    console.error('Failed to set tokens:', error);
  }
}

export async function isAuthenticated() {
  const access_token = sessionStorage.getItem('access_token');
  if (!access_token) return false;

  const headers = new Headers({
    Authorization: `Bearer ${access_token}`,
  });

  const requestOptions = {
    method: 'GET',
    headers,
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      `https://${domain}/oauth2/userInfo`,
      requestOptions
    );
    // Check if the response status is ok
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const { email } = await response.json();
    return !!email;
  } catch (error) {
    console.error('Authentication check failed:', error);
    return false;
  }
}

// Returns active token if not expired
export function currentSession() {
  const token = sessionStorage.getItem('id_token');
  if (!token) return null;

  const decoded = jwtDecode(token);
  return Date.now() < decoded.exp * 1000 ? decoded : null;
}

export async function getAuthenticatedHeaders() {
  let id_token = sessionStorage.getItem('id_token');
  if (!id_token) {
    console.error(
      'No JWT found, session is not active. Please login through your IDP'
    );
    return router.push('/forbidden');
  }

  if (!currentSession()) {
    // attempt to refresh tokens
    const refresh_token = sessionStorage.getItem('refresh_token');
    await getTokens(null, refresh_token);
    id_token = sessionStorage.getItem('id_token');
  }

  const reqData = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
      Authorization: `Bearer ${id_token}`,
      redirect: 'follow',
    },
    response: true,
  };
  return reqData;
}

function setTokens(id_token = null, access_token = null, refresh_token = null) {
  if (id_token) sessionStorage.setItem('id_token', id_token);
  if (access_token) sessionStorage.setItem('access_token', access_token);
  if (refresh_token) sessionStorage.setItem('refresh_token', refresh_token);
}

export function clearTokens() {
  sessionStorage.removeItem('id_token');
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
}
