import StatsIcon from '@/assets/sidebar-icons/StatsIcon.vue';
import TheLeadIcon from '@/assets/sidebar-icons/TheLeadIcon.vue';
import UsersIcon from '@/assets/sidebar-icons/UsersIcon.vue';
import WorklistsIcon from '@/assets/sidebar-icons/WorklistsIcon.vue';
import OrgSettingsIcon from '@/assets/sidebar-icons/OrgSettingsIcon.vue';
import { useUsersStore } from '@/stores/useUsers';
import LoginView from '@/views/auth/LoginView.vue';
import LogoutView from '@/views/auth/LogoutView.vue';
import DocumentView from '@/views/document/DocumentView.vue';
import LeadView from '@/views/lead/LeadView.vue';
import WorkItem from '@/views/lead/WorkItem.vue';
import StatsView from '@/views/stats/StatsView.vue';
import UsersView from '@/views/users/UsersView.vue';
import UserDetail from '@/views/users/UserDetail.vue';
import AdminPanelView from '@/views/admin-panel/AdminPanelView.vue';
import GamePlan from '@/views/refactor/GamePlan.vue';
import { getTokens, isAuthenticated } from '../utils/auth';
import { useJwt } from '@vueuse/integrations/useJwt';
import { createRouter, createWebHistory } from 'vue-router';
import NotFoundPage from '@/views/common/NotFoundPage.vue';
import UserForbiddenPage from '@/views/common/UserForbiddenPage.vue';
import UpsertWorkListView from '@/views/refactor/UpsertWorkListView';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useRbacStore } from '@/stores/useRbac';

const publicRoutes = ['/login', '/notFound'];

async function validateAuth(to) {
  if (publicRoutes.includes(to.path)) return true;

  // Validate auxo user
  const id_token = sessionStorage.getItem('id_token');
  if (!id_token || id_token == 'undefined') return { name: 'forbidden' };

  const { payload } = useJwt(id_token);
  const users_store = useUsersStore();
  const user = await users_store.getUserByEmail(payload.value.email);

  if (!user) {
    return { name: 'forbidden' };
  }

  // Validate Cognito user
  try {
    if (await isAuthenticated()) return true;
    throw new Error('User is not authenticated');
  } catch (err) {
    console.warn('Authentication check failed:', err);
    return { name: 'forbidden' };
  }
}

async function validatePermission(to) {
  const rbac_store = useRbacStore();
  try {
    const currentRoute = routes.find(
      route =>
        route.path === to.path ||
        route.path === to.matched.find(e => e.name === route.name)?.path
    );

    if (currentRoute?.featureFlag) {
      const featureFlagPass = process.env[currentRoute.featureFlag] === 'true';
      if (!featureFlagPass) return { name: 'lead' };
    }

    // Validate if user has access to route
    const hasAccessToRoute = rbac_store.hasAccessToRoute(currentRoute.name);
    if (!hasAccessToRoute) return { name: 'lead' };

    // User has route access continue on
    return true;
  } catch (err) {
    console.warn('Permission check failed:', err);
    return false;
  }
}

// Send Prod users to 404 not found page if they try to access hosted UI
function useHostedUI() {
  const useHostedUI = process.env.VUE_APP_COGNITO_USE_HOSTED_UI === 'true';

  if (!useHostedUI) {
    return { name: 'notfound' };
  }
}

export const routes = [
  {
    path: '/',
    redirect: '/lead',
  },
  {
    path: '/status',
    redirect: '/lead',
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: [useHostedUI],
    component: LoginView,
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
  },
  {
    path: '/lead',
    name: 'lead',
    component: LeadView,
    beforeEnter: [validateAuth, validatePermission],
    sidebar: true,
    icon: <TheLeadIcon class="w-7 h-7" />,
    sidebarName: 'High Five',
  },
  {
    path: '/lead/:id',
    name: 'leadId',
    component: WorkItem,
    beforeEnter: [validateAuth, validatePermission],
  },
  {
    path: '/document/:claim',
    name: 'document',
    component: DocumentView,
    beforeEnter: [validateAuth, validatePermission],
  },
  {
    path: '/gameplan',
    name: 'gameplan',
    component: GamePlan,
    beforeEnter: [validateAuth, validatePermission],
    sidebar: true,
    icon: <WorklistsIcon class="w-7 h-7" />,
    sidebarName: 'Game Plan',
  },
  {
    path: '/gameplan/new',
    name: 'newWorklist',
    component: UpsertWorkListView,
    beforeEnter: [validateAuth, validatePermission],
    sidebar: false,
    icon: <WorklistsIcon class="w-7 h-7" />,
    sidebarName: 'Gameplan (Flagged)',
  },
  {
    path: '/gameplan/edit',
    name: 'editWorklist',
    component: UpsertWorkListView,
    beforeEnter: [validateAuth, validatePermission],
    sidebar: false,
    icon: <WorklistsIcon class="w-7 h-7" />,
    sidebarName: 'Gameplan (Flagged)',
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    beforeEnter: [validateAuth, validatePermission],
    sidebar: true,
    icon: <UsersIcon class="w-7 h-7" />,
    sidebarName: 'Users',
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView,
    beforeEnter: [validateAuth, validatePermission],
    sidebar: true,
    icon: <StatsIcon class="w-7 h-7" />,
    sidebarName: 'Stats',
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPanelView,
    beforeEnter: [validateAuth, validatePermission],
    sidebar: true,
    icon: <OrgSettingsIcon />,
    sidebarName: 'Organization Settings',
  },
  {
    path: '/users/:auxo_user_id',
    name: 'userdetail',
    component: UserDetail,
    beforeEnter: [validateAuth, validatePermission],
  },
  {
    name: 'notfound',
    path: '/notfound',
    component: NotFoundPage,
  },
  {
    name: 'forbidden',
    path: '/forbidden',
    component: UserForbiddenPage,
  },
  {
    name: 'catchAll',
    path: '/:catchAll(.*)',
    beforeEnter: [validateAuth],
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Route guard for Individual worklist Page
  const gameplan_store = useGamePlanStore();

  if (
    gameplan_store.editMode &&
    from.fullPath?.includes('gameplan') &&
    !to.fullPath?.includes('gameplan')
  ) {
    const confirm = window.confirm('Are you sure you want to leave this page?');
    if (confirm) {
      // User confirmed, allow the navigation
      gameplan_store.deleteDraftWorkStrategy().then(() => {
        next();
      });
    } else {
      // User canceled, prevent the navigation
      next(false);
    }
  } else if (
    from.path === '/' &&
    to.path === '/lead' &&
    to?.redirectedFrom?.path === '/status' &&
    to?.query?.code
  ) {
    // get tokens on initial login

    await getTokens(to.query.code, null);
    next();
  } else {
    // Continue to the next route
    next();
  }
});
export default router;
