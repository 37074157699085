import { defineStore } from 'pinia';
import getPath from '../utils/getPath';
import { signOut } from '@/utils/auth';
import { getUserInitials } from '@/utils/helpers';
import * as helpers from '@/utils/helpers';
import { apiGet, apiPost } from '@/utils/api';

const SYSTEM_USER = 'sys';

export const useUsersStore = defineStore('users', {
  state: () => ({
    users: [],
    notFound: false,
    statusLookup: [],
    activeUser: null,
    status: null,
    usersLoading: false,
  }),
  getters: {
    /**
     * Returns the full name of a user with the given username.
     */
    getUserFullName: state => {
      return username => {
        if (!username) return null;
        const user = state.users.find(
          user => user?.username?.toLowerCase() === username.toLowerCase()
        );

        if (username === SYSTEM_USER) return 'System User';
        return user?.full_name;
      };
    },
    getGroupOfUsersNames: state => usersArray => {
      if (!state.users.length) return;

      return usersArray.map(e => {
        return {
          name: `${e.first_name} ${e.last_name}`,
          is_active: e.is_active,
        };
      });
    },
    getUsernames: state => {
      return state.users.map(user => {
        return {
          key: user.username,
          value: `${user.first_name} ${user.last_name}`,
        };
      });
    },
    getUserById: state => {
      return userId => {
        const user = state.users.find(
          user => user.user_id || user.auxo_user_id === userId
        );
        return {
          ...user,
          initials: getUserInitials(user),
        };
      };
    },
    getActiveUsersRoles: state => {
      return state.activeUser['cognito:groups'];
    },
    isActiveUserAdmin: state => {
      return state.activeUser['cognito:groups'].some(
        userRole => userRole === 'ADM'
      );
    },
    activeUsers: state => {
      return state.users.filter(user => user.is_active);
    },
    getUserByUsername: state => {
      return username => {
        if (!username) return null;
        const user = state.users.find(
          user => user.username.toLowerCase() === username.toLowerCase()
        );
        return {
          ...user,
          initials: getUserInitials(user),
        };
      };
    },
    getUserInitials: state => {
      return username => {
        if (!username) return null;

        const user = state.users.find(
          user => user.username.toLowerCase() === username.toLowerCase()
        );
        return user ? user.first_name[0] + user.last_name[0] : null;
      };
    },
    getUserPicture: state => {
      return username => {
        if (!username) return null;

        return (
          state.users.find(
            user => user.username.toLowerCase() === username.toLowerCase()
          )?.photo_url || null
        );
      };
    },
    sortUsers: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.users);
      };
    },
    getActiveUserAttribute: state => {
      return attr => {
        return state.activeUser[attr];
      };
    },
  },
  actions: {
    setStatus(status) {
      this.status = status;
      localStorage.setItem('userStatus', this.status?.name);
      return status;
    },
    async getAllUsers() {
      this.usersLoading = true;
      try {
        const response = await apiGet(getPath('users'));

        this.users = response.data
          .map(user => {
            user.photo_url = user.photo_url || 'none';
            const full_name = [user?.first_name, user?.last_name]
              .filter(e => e)
              .join(' ');

            return {
              ...user,
              username: helpers.getUsername(user.email),
              full_name,
              informal_title: helpers.formatUserRoles(user?.user_roles),
              label: full_name,
            };
          })
          .sort((a, b) => {
            return a.first_name.localeCompare(b.first_name, 'en', {
              sensitivity: 'accent',
            });
          });
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.usersLoading = false;
      }
    },
    async getUserByEmail(email) {
      if (!email) return;
      // TODO: when cognito returns auxo_user_id remove this and call /users/<auxo_user_id>

      this.notFound = false;
      try {
        const response = await apiGet(
          getPath(`users?email=${email}&expect_single=true`)
        );
        if (!response.data) {
          this.notFound = true;
          console.error(`User email ${email} not found`);
          return null;
        }
        const user = response.data;
        return {
          ...user,
          username: helpers.getUsername(user.email),
        };
      } catch (err) {
        const errorBody = await err.response.json();
        console.error('Could not get user');
        console.error(errorBody?.message ?? errorBody);
        this.notFound = true;
      }
    },

    async getUser(auxo_user_id) {
      this.notFound = false;
      try {
        const response = await apiGet(getPath(`users/${auxo_user_id}`));
        const user = response.data;
        return {
          ...user,
          username: helpers.getUsername(user.email),
        };
      } catch (err) {
        const errorBody = await err.response.json();
        console.error('Could not get user');
        console.error(errorBody?.message ?? errorBody);
        this.notFound = true;
      }
    },
    async logOut() {
      sessionStorage.removeItem('activityDrafts');
      await this.authActivity('logout');
      await signOut();
    },
    async authActivity(action) {
      try {
        const response = await apiPost(
          getPath(`users/auth_activity?action=${action}`)
        );
        const data = response.data;
        return data;
      } catch (err) {
        const errorBody = await err.response.json();
        console.error('Could not track login/logout activity.');
        console.error(errorBody?.message ?? errorBody);
      }
    },
  },
});
