<template>
  <button
    v-if="!showBot"
    class="absolute right-4 bottom-4 z-10 p-3 py-3 rounded-full bg-primary border-4 border-green-300 expandable-button animate-pulse-colors"
    @click="openChatBot"
  >
    <div class="flex gap-2">
      <img :src="AuxoIcon" class="w-6 h-6" />
    </div>
  </button>
  <div
    v-if="showBot"
    class="mr-[-1.75rem] my-[-1.75rem] min-w-[350px] max-w-[350px]"
  >
    <div
      class="absolute flex flex-col border p-4 bg-white min-w-[350px] max-w-[350px] h-[93vh] right-0 z-10"
    >
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-3">
          <div
            class="p-2 rounded-full bg-primary flex items-center justify-center"
          >
            <img :src="AuxoIcon" class="w-4 h-4" />
          </div>
          <div class="flex flex-col">
            <h1>Auxo AI</h1>
            <p class="text-xs">
              <DescriptionPopover
                :id="'disclaimer'"
                :hover-text="'Disclaimer'"
                :link="'https://revology.atlassian.net/servicedesk/customer/portal/1/article/64192734'"
              >
                <h3 class="text-sm font-bold">Disclaimer</h3>
                <p>
                  This AI assistant is designed to provide guidance and
                  recommendations to assist with decision-making. While it
                  strives to provide accurate and helpful guidance, it is not
                  perfect and may make mistakes. Users are responsible for
                  ensuring that their actions comply with industry guidelines,
                  regulations, and best practices. If you find an issue, please
                  report it via in-product feedback or support.
                </p>
                <p>
                  <a
                    href="https://revology.atlassian.net/servicedesk/customer/portal/1/article/64192734"
                    target="_blank"
                    class="underline text-blue-500"
                    >Read More</a
                  >
                </p>
              </DescriptionPopover>
            </p>
          </div>
        </div>
        <div class="flex gap-5 items-center">
          <BaseButton
            :disabled="responseLoading"
            :class="responseLoading ? 'hidden' : ''"
            @click="clearChatBot"
            ><img :src="TrashIcon"
          /></BaseButton>
          <BaseButton @click="closeChatBot">
            <img :src="CloseIcon2" />
          </BaseButton>
        </div>
      </div>
      <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />
      <p class="text-gray-300 mb-3">
        How can I help? Ask me a question about your claim.
      </p>
      <div class="grow"></div>
      <div
        id="scrollableDiv"
        class="flex flex-col items-center gap-3 overflow-y-auto overflow-x-hidden pt-3 will-change-auto"
      >
        <div
          v-for="button in props.buttonList"
          :key="button.questionText"
          class="w-full flex justify-center"
        >
          <button
            class="bg-gray-200 hover:bg-blue-100 w-5/6 rounded-lg text-left p-2"
            @click="askQuestion(button)"
          >
            {{ button.questionText }}
          </button>
        </div>

        <div
          v-for="message in messageList"
          :key="message.id"
          class="flex flex-col gap-4 w-full"
        >
          <ChatBubble
            :text="message.text"
            :user="message.user"
            :timestamp="message.timestamp"
          />
        </div>

        <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 w-full" />
        <form
          class="flex gap-3 w-full items-center"
          @submit.prevent="askQuestion()"
        >
          <div class="flex w-full flex-col">
            <input
              v-model="prompt"
              :disabled="responseLoading"
              required="true"
              type="text"
              placeholder="Ask Auxo about your claim"
              :class="[
                'border p-2 w-full',
                errorMessage ? 'border-red-500' : '',
                responseLoading ? 'bg-gray-200' : '',
              ]"
            />
            <p v-if="errorMessage" class="text-red-500 text-rev-sm">
              {{ errorMessage }}
            </p>
          </div>

          <SolidButton
            type="submit"
            :disabled="!!errorMessage"
            class="bg-secondary border-secondary p-2"
          >
            Send
          </SolidButton>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useUsersStore } from '@/stores/useUsers';
import { useChatbotStore } from '@/stores/useChatbot';

import SolidButton from './buttons/SolidButton.vue';
import BaseButton from './buttons/BaseButton.vue';
import ChatBubble from './chat-ai/ChatBubble.vue';
import CloseIcon2 from '@/assets/close-icon-2.svg';
import TrashIcon from '@/assets/trashcan.svg';
import AuxoIcon from '@/assets/auxo-icon.svg';
import DescriptionPopover from '@/components/DescriptionPopover.vue';
import { useWorkItemsStore } from '@/stores/useWorkItems';

const route = useRoute();

const props = defineProps({
  buttonList: {
    type: Array,
    default: () => [{ questionText: '', endpoint: '' }],
  },
  page: {
    type: String,
    default: '',
  },
  rsn: {
    type: String,
    default: '',
  },
});

const usersStore = useUsersStore();
const chatbotStore = useChatbotStore();
const workItems_store = useWorkItemsStore();

let showBot = ref(false);
let errorMessage = ref(false);
let prompt = ref('');
let messageList = ref([]);
let responseLoading = ref(false);

watch(prompt, () => {
  checkCharacterCount();
});

// Edge case where user navigates from one work item to another using search
watch(route, async to => {
  clearChatBot();
  closeChatBot();
});

const openChatBot = () => {
  showBot.value = true;
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
};
const closeChatBot = () => {
  showBot.value = false;
};

const clearChatBot = () => {
  messageList.value = [];
  chatbotStore.sessionID = '';
};

const askQuestion = async buttonObject => {
  let formattedQuestion = '';

  if (buttonObject) {
    formattedQuestion = buttonObject.questionText;
  } else {
    checkCharacterCount();
    const promptText = prompt.value.trim();
    if (!promptText || promptText.includes('`')) {
      errorMessage.value = 'Please enter a valid prompt';
      return;
    }
    formattedQuestion = promptText;
    prompt.value = '';
  }

  // Add the user's question to the message list
  messageList.value.push({
    text: { body: formattedQuestion },
    timestamp: new Date(),
    user: usersStore.activeUser,
  });

  // Placeholder for the response
  messageList.value.push({ timestamp: new Date() });

  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });

  try {
    responseLoading.value = true;
    await workItems_store.fetchWorkItem(props.rsn);
    const rspGroup = {
      phase: workItems_store.workItem['phase'],
      status: workItems_store.workItem['status'],
      reason: workItems_store.workItem['reason'],
      rsn: props.rsn,
    };
    const response = await chatbotStore.generateAndFormatResponse(
      rspGroup,
      usersStore.activeUser,
      {
        endpoint: buttonObject?.endpoint,
        prompt: buttonObject ? undefined : formattedQuestion,
        messageList: buttonObject ? undefined : messageList.value,
      }
    );

    // Update the placeholder with the received response
    messageList.value[messageList.value.length - 1] = {
      timestamp: new Date(),
      text: response,
    };
  } catch (error) {
    // Handle errors gracefully
    messageList.value[messageList.value.length - 1] = {
      timestamp: new Date(),
      text: {
        body: `There was an error retrieving your response: ${error.message}`,
      },
    };
  } finally {
    responseLoading.value = false;
  }
};

const checkCharacterCount = () => {
  const characterLimit = 1000;
  let errorMes =
    prompt.value.length <= characterLimit
      ? null
      : `Prompt must be less than ${characterLimit} characters`;
  errorMessage.value = errorMes;
};
</script>

<style scoped>
@keyframes pulseColors {
  0% {
    box-shadow: 0 0 0 0px rgba(132, 225, 188, 0.5);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.animate-pulse-colors {
  animation: pulseColors 2s infinite;
}
</style>
