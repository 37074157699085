import { getAuthenticatedHeaders } from './auth';

class ResponseError extends Error {
  constructor(message, res) {
    super(message);
    this.status = res.status;
    this.response = res;
  }
}

/**
 * @param {string} endpoint URL to fetch
 * @param {Header} header Header Object
 * @param {URLSearchParams} queryStringParams Optional Params Object
 */

// GET request with optional query parameters
export async function apiGet(
  endpoint,
  queryStringParams = null,
  header = null
) {
  if (!header) {
    header = await getAuthenticatedHeaders();
  }
  if (queryStringParams) {
    endpoint = `${endpoint}?${queryStringParams}`;
  }
  const response = request(endpoint, header, 'GET');
  return response;
}

// POST request
export async function apiPost(
  endpoint,
  data,
  header = null,
  queryStringParams = null
) {
  if (!header) {
    header = await getAuthenticatedHeaders();
  }
  if (queryStringParams) {
    endpoint = `${endpoint}?${queryStringParams}`;
  }
  return request(endpoint, header, 'POST', data);
}

// PUT request
export async function apiPut(endpoint, data, header = null) {
  if (!header) {
    header = await getAuthenticatedHeaders();
  }
  return request(endpoint, header, 'PUT', data);
}

// DELETE request
export async function apiDelete(endpoint, header = null) {
  if (!header) {
    header = await getAuthenticatedHeaders();
  }
  return request(endpoint, header, 'DELETE');
}

async function request(endpoint, header, method, data = null) {
  const config = {
    mode: 'cors',
    method: method,
    ...header,
  };

  // Attach the body only for POST and PUT requests
  if (data && (method === 'POST' || method === 'PUT')) {
    config.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(endpoint, config);
    // Check if the response status is ok
    if (!response.ok) {
      throw new ResponseError(
        `Fetch Error:  Status: ${response.status}`,
        response
      );
    }

    // If there's no content (e.g. DELETE), return empty
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  } catch (error) {
    if (error instanceof ResponseError) {
      console.log(`API Error. Status Code: ${error.response.status}`);
      throw error;
    } else {
      console.log(`API Error (Invalid URL or Network Error) Message: ${error}`);
    }
  }
}
