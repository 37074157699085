<template>
  <div
    v-if="props.user.first_name == 'Auxo AI'"
    class="flex items-start gap-2.5"
  >
    <UserPicture
      :user="props.user"
      :disable-popper="true"
      :style="'border-transparent'"
    />
    <div class="flex flex-col gap-1 w-[85%]">
      <div class="flex items-center space-x-2 rtl:space-x-reverse">
        <span class="text-sm font-semibold text-gray-900 dark:text-white">{{
          props.user.first_name
        }}</span>
        <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
          {{
            props.timestamp.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          }}
        </span>
      </div>
      <div
        class="flex flex-col leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700"
      >
        <BotResponse v-if="props.text.body" :text="props.text" />
        <p v-else class="text-sm font-normal text-gray-900 dark:text-white">
          <ChatSkeleton />
        </p>
      </div>
    </div>
  </div>
  <div v-else class="flex items-start gap-2.5">
    <div class="flex flex-col gap-1 w-full">
      <div class="flex items-center space-x-2 ltr:space-x-reverse justify-end">
        <span class="text-sm font-semibold text-gray-900 dark:text-white"
          >{{ props.user.first_name }} {{ props.user.last_name }}</span
        >
        <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
          {{
            props.timestamp.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          }}
        </span>
      </div>
      <div
        class="flex flex-col leading-1.5 p-4 border-gray-200 bg-[#cceffe] rounded-s-xl rounded-ee-xl dark:bg-gray-700"
      >
        <p class="font-normal text-gray-900 dark:text-white overflow-auto">
          {{ props.text.body }}
        </p>
      </div>
    </div>
    <UserPicture :user="props.user" :disable-popper="true" />
  </div>
</template>

<script setup>
import UserPicture from '../UserPicture.vue';
import BotResponse from './BotResponse.vue';
import ChatSkeleton from './ChatSkeleton.vue';
import ChatAvatar from '@/assets/chatAvatar.svg';

const props = defineProps({
  user: {
    type: {},
    default: {
      first_name: 'Auxo AI',
      last_name: '',
      initials: 'AI',
      photo_url: ChatAvatar,
    },
  },
  text: {
    type: {},
    default: {
      body: '',
    },
  },
  timestamp: {
    type: Date,
    default: new Date(),
  },
});
</script>
