<template>
  <ModalComponent
    :modal-active="showCancel"
    :close="() => (showCancel = false)"
    :custom-class="'w-[25em]'"
  >
    <div>
      <h1 class="text-2xl m-0.5 mb-3">
        Are you sure you want to exit without saving the Game Plan?
      </h1>
      <div class="mt-[2em] text-left text-base text-gray-400">
        <div class="mt-5 flex justify-between">
          <OutlinedButton
            class="w-52 mr-2 text-xs"
            type="button"
            @click="() => (showCancel = false)"
          >
            No, go back
          </OutlinedButton>
          <SolidButton
            class="w-52 text-xs"
            type="button"
            @click="
              () => {
                revertAndGoBack();
              }
            "
          >
            Yes, continue without saving
          </SolidButton>
        </div>
      </div>
    </div>
  </ModalComponent>
  <LogoutModal />
  <TimeoutModal />
  <PageView>
    <div class="mb-4">
      <div class="flex items-end justify-between">
        <div class="flex flex-col gap-2">
          <p class="text-2xl text-primary font-bold">
            {{ gameplan_store.editMode ? 'Edit Game Plan' : 'Game Plan' }}
          </p>
          <GamePlanDropdown />
          <SolidButton
            v-show="gameplan_store.editMode"
            class="px-5 !h-8 w-32 text-xs"
            @click="
              () => {
                queryBuilder.resetQuery();
                // Default new worklist to have 1 empty rule
                queryBuilder.query.rules = [
                  {
                    id: Date.now(),
                    ...EMPTY_INPUT,
                  },
                ];
                queryBuilder.resetWorklist();
                queryBuilder.attempt = false;
                queryBuilder.defaultWorklist = false;
                router.push(`/gameplan/new`);
              }
            "
          >
            New Worklist
          </SolidButton>
        </div>
        <StatsPanel
          v-if="!gameplan_store.editMode"
          :work-item-count="gameplan_store.getWorkItemCount"
          :total-balance="gameplan_store.getTotalBalance"
        />
        <div v-if="!gameplan_store.editMode">
          <div
            v-if="gameplan_store.editingMessage.length"
            class="italic text-red-one"
          >
            {{ gameplan_store.editingMessage }}
          </div>
          <div class="flex items-end gap-4">
            <GamePlanSearch
              @has-query-terms="
                value => {
                  if (value) {
                    hasQueryTerms = true;
                  }
                }
              "
            />
            <SolidButton
              v-if="useRbac_store.hasWriteRole('EDIT_GAMEPLAN')"
              class="px-5 !h-8 w-48 drop-shadow-md"
              :class="
                (!gameplan_store.isCurrentGameplanSelected ||
                  websocket_store.connection?.readyState !==
                    WEBSOCKET_STATES.OPEN ||
                  gameplan_store.isStrategyLocked) === true
                  ? '!bg-[#8FA4B3] !border-[#8FA4B3]'
                  : ''
              "
              :disabled="
                !gameplan_store.isCurrentGameplanSelected ||
                websocket_store.connection?.readyState !==
                  WEBSOCKET_STATES.OPEN ||
                gameplan_store.isStrategyLocked
              "
              :title="
                gameplan_store.isCurrentGameplanSelected &&
                !gameplan_store.isStrategyLocked &&
                websocket_store.connection?.readyState === WEBSOCKET_STATES.OPEN
                  ? 'Edit Game Plan'
                  : 'Disabled'
              "
              @click="() => editGamePlan()"
            >
              <span class="text-sm">Edit Game Plan</span>
            </SolidButton>
          </div>
        </div>
        <div v-else class="flex items-center gap-4">
          <OutlinedButton
            class="px-5 !h-8 text-xs"
            @click="() => (showCancel = !showCancel)"
          >
            Cancel
          </OutlinedButton>
          <SolidButton
            v-if="useRbac_store.hasWriteRole('EDIT_GAMEPLAN')"
            class="px-5 !h-8 text-xs"
            @click="() => saveWorkStrategy()"
          >
            Save Game Plan
          </SolidButton>
        </div>
      </div>
    </div>
    <GamePlanTable />
    <div
      v-if="isDevelopment() || isTest()"
      class="bg-white drop-shadow-md p-3 my-[1em]"
    >
      <h1 class="text-xl text-primary font-bold pb-2">QAology</h1>
      <div class="flex gap-4">
        <SolidButton
          class="px-5 !h-8"
          @click="
            async () => {
              if (hasQueryTerms) {
                const execId = gameplan_store.getCurrentExecId;
                await gameplan_store.getWorkLists(1, execId, null);
              }
              gameplan_store.createDraftWorkStrategy();
            }
          "
        >
          Create Draft Strategy
        </SolidButton>
        <SolidButton
          class="px-5 !h-8"
          @click="
            () => {
              console.log(gameplan_store.getSaveState);
            }
          "
        >
          Log Save State
        </SolidButton>
        <SolidButton
          class="px-5 !h-8"
          @click="
            () =>
              console.log(
                `Draft data ID is: ${gameplan_store?.draftData?.work_strategy_draft_id}`
              )
          "
        >
          Show Draft ID
        </SolidButton>
        <SolidButton class="px-5 !h-8" @click="() => websocket_store.close()">
          Disconnect WebSocket
        </SolidButton>
        <SolidButton class="px-5 !h-8" @click="() => refreshTokens()">
          Refresh Auth Tokens
        </SolidButton>
        <SolidButton class="px-5 !h-8" @click="() => signOut()">
          Revoke Auth Tokens
        </SolidButton>
      </div>
    </div>
  </PageView>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import PageView from '../../components/PageVue.vue';
import GamePlanTable from './GamePlanTable.vue';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import StatsPanel from './StatsPanel.vue';
import GamePlanDropdown from './GamePlanDropdown.vue';
import GamePlanSearch from './GamePlanSearch.vue';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { WEBSOCKET_STATES, useWebSockets } from '@/stores/useWebSockets';
import { isDevelopment, isTest } from '@/utils/helpers';
import { getTokens, signOut } from '@/utils/auth';
import ModalComponent from '@/components/ModalComponent.vue';
import router from '@/router';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { useEventListener } from '@vueuse/core';
import LogoutModal from './LogoutModal.vue';
import TimeoutModal from './TimeoutModal.vue';
import { useRspLookupStore } from '@/stores/useRspLookup';
import { EMPTY_INPUT } from '../query-builder/constants';
import { useLookupStore } from '@/stores/useLookups';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { useRbacStore } from '@/stores/useRbac';

const gameplan_store = useGamePlanStore();
const websocket_store = useWebSockets();
const queryBuilder = useQueryBuilderStore();
const rspLookup_store = useRspLookupStore();
const lookup_store = useLookupStore();
const hasQueryTerms = ref(false);
const useRbac_store = useRbacStore();

const STRATEGY_ID = 1;

onMounted(async () => {
  await rspLookup_store.fetchRspLookup();
  await lookup_store.getPayerPlans();
});

const showCancel = ref(false);

const editGamePlan = async () => {
  gameplan_store.isStrategyLocked = true;
  if (hasQueryTerms.value) {
    const execId = gameplan_store.getCurrentExecId;
    await gameplan_store.getWorkLists(STRATEGY_ID, execId, null);
  }
  gameplan_store.createDraftWorkStrategy();
};

const revertAndGoBack = () => {
  gameplan_store.deleteDraftWorkStrategy().then(async () => {
    gameplan_store.resetWorklists();
    gameplan_store.editMode = false;
    showCancel.value = false;
  });
};

const refreshTokens = async () => {
  const refresh_token = sessionStorage.getItem('refresh_token');
  await getTokens(null, refresh_token);
};

const saveWorkStrategy = () => {
  gameplan_store.saveWorkStrategy();
  gameplan_store.editMode = false;
  gameplan_store.isStrategyLocked = true;
};

// Don't lose the users progress!
useEventListener(window, 'beforeunload', e => {
  if (!gameplan_store.editMode) {
    return;
  }

  e.preventDefault();

  // Customize the confirmation message
  const confirmationMessage = 'Are you sure you want to leave this page?';

  // Display the confirmation message
  event.returnValue = confirmationMessage; // Some older browsers require this property to be set

  return confirmationMessage;
});
</script>

<style></style>
