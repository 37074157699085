<template>
  <Suspense>
    <div class="h-screen w-full">
      <router-view />
    </div>
  </Suspense>
</template>

<script setup>
import { useIdle } from '@vueuse/core';
import { useJwt } from '@vueuse/integrations/useJwt';
import { clearTokens } from './utils/auth';
import { watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { useUsersStore } from '@/stores/useUsers';
import { useMetrics } from '@/stores/useMetrics';
import { useWebSockets } from '@/stores/useWebSockets';
import { getUserInitials } from './utils/helpers';

const users_store = useUsersStore();
const metrics_store = useMetrics();
const websocket_Store = useWebSockets();
const route = useRoute();

const TIMEOUT = process.env.VUE_APP_AUTH_TIMEOUT || 30;

const { idle } = useIdle(TIMEOUT * 60 * 1000);

onMounted(async () => {
  await handlePageRefresh();
});

// Initial login
watch(route, async route => {
  if (route?.redirectedFrom?.path === '/status') {
    await handlePageRefresh();
    users_store.authActivity('login');
  }
});

watch(
  () => idle.value,
  () => {
    if (idle.value == true) {
      users_store.logOut();
    }
  }
);

async function handlePageRefresh() {
  const id_token = sessionStorage.getItem('id_token');
  if (!id_token || id_token == 'undefined') return;

  const { payload } = useJwt(id_token);
  const user = await users_store.getUserByEmail(payload.value.email);

  if (!user) {
    clearTokens();
    return router.push('/login');
  }
  // Load metrics
  metrics_store.activeUserID = user.auxo_user_id;
  metrics_store.activeUsername = user.username;
  metrics_store.activeUserDailyComplete = 0;
  metrics_store.queryForUserMetrics();

  // Load user
  users_store.activeUser = {
    ...payload.value,
    initials: getUserInitials(user),
    username: user.username,
    auxo_user_id: user.auxo_user_id,
    photo_url: user.photo_url || 'none',
    is_active: user.is_active,
  };

  if (!users_store.isLoading) {
    users_store.isLoading = true;
    await users_store.getAllUsers();
    users_store.isLoading = false;
  }

  try {
    websocket_Store.connect();
  } catch (error) {
    console.error('WebSocket connection failed:', error);
  }
}
</script>
